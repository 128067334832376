@font-face {
    font-family: 'Estedad-FD-Regular';
    src: local('Estedad-FD-Regular'), url("./Fonts/Estedad-FD-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Estedad-FD-Bold';
    src: local('Estedad-FD-Bold'), url("./Fonts/Estedad-FD-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Estedad-FD-Light';
    src: local('Estedad-FD-Light'), url("./Fonts/Estedad-FD-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-Black';
    src: local('YekanBakhFaNum-Black'), url("./Fonts/YekanBakhFaNum-Black.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-Bold';
    src: local('YekanBakhFaNum-Bold'), url("./Fonts/YekanBakhFaNum-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-ExtraBlack';
    src: local('YekanBakhFaNum-ExtraBlack'), url("./Fonts/YekanBakhFaNum-ExtraBlack.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-ExtraBold';
    src: local('YekanBakhFaNum-ExtraBold'), url("./Fonts/YekanBakhFaNum-ExtraBold.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-Light';
    src: local('YekanBakhFaNum-Light'), url("./Fonts/YekanBakhFaNum-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-Regular';
    src: local('YekanBakhFaNum-Regular'), url("./Fonts/YekanBakhFaNum-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-SemiBold';
    src: local('YekanBakhFaNum-SemiBold'), url("./Fonts/YekanBakhFaNum-SemiBold.ttf") format('truetype');
}

@font-face {
    font-family: 'Yekan-Thin';
    src: local('YekanBakhFaNum-Thin'), url("./Fonts/YekanBakhFaNum-Thin.ttf") format('truetype');
}

body {
    font-family: 'Yekan-SemiBold', sans-serif!important;
    font-size: 12px!important;
    background-color: #F6F9FF!important;
}

a:link,
a:visited {
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}

.text-bold {
    font-family: 'Yekan-Bold', sans-serif!important;
}

.accent-color {
    color: #dba52f;
}

.accent-bg {
    background-color: #dba52f;
}

.accent-border {
    border: 1px solid #dba52f!important;
}

.border-dashed {
    border: 1px dashed blue!important;
}

.myform {
    font-family: 'Yekan-SemiBold', sans-serif!important;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-top: 15px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}